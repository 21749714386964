export type BannerImage =
  | 'promotion-image-1'
  | 'promotion-image-2'
  | 'promotion-image-3'
  | 'promotion-image-4'

export type Message = {
  title: string
  subtitle: string
  ctaText: string
}

export type BannerConfig = {
  id: string
  content: string
  href?: string
}

export type PromotionData = {
  id: string
  campaignName: string
  bannerImage: BannerImage
  surtitle: string
  title: string
  subtitle: string
  submitButtonText: string
  success: Message
  userRegisteredError: Message
  evolveEmployeeError?: Message
  bannerConfig?: BannerConfig
  analyticsEventName: string
  sms?: boolean
  enabled: boolean
}

const success = {
  title: '<span style="color: #A848BD;">Congrats!</span> You’re a Vacation VIP',
  subtitle:
    'Thanks for signing up! Your travel credits are on their way to your inbox, so keep an eye on your emails. In the meantime, explore our homes and start daydreaming about where you’ll head next.',
  ctaText: 'Browse all rentals',
}

const userRegisteredError = {
  title: 'Looks like you’re already a Vacation VIP',
  subtitle:
    'Your email address and/or phone number have already been used for this promotion. The upside? You can start saving your favorite homes for future trip planning.',
  ctaText: 'Explore vacation rentals',
}

const evolveEmployeeError = {
  title: 'Wait, do you work here?',
  subtitle:
    'The bad news? Evolve employees are not eligible for this promotion. The good news? You’ll receive travel credits every year on the anniversary of your start date. You might as well fill up your wishlist until then!',
  ctaText: 'Start browsing rentals',
}

const promotionData: PromotionData[] = [
  {
    id: '61b1f8c3-1491-4168-8787-a3531a307c26',
    campaignName: 'inhome_magnet_ftc_1',
    bannerImage: 'promotion-image-2',
    surtitle: 'TIME FOR MORE VACATION VIBES',
    title: 'Get $50 in travel credits',
    subtitle:
      'What’s even better than having a wonderful time at an Evolve property? Spending less when you stay with us again! Fill out the form below to get travel tips in your inbox and <strong>receive $50 in Evolve travel credits</strong> to use toward one of our 30,000+ rentals nationwide.',
    submitButtonText: 'Sign me up to save',
    success,
    userRegisteredError,
    evolveEmployeeError,
    bannerConfig: {
      id: 'promotion_inhome_magnet_ftc_1',
      content:
        'Questions during your stay? Check out our <u>Help Center</u> for all your answers!',
      href: '/contact',
    },
    analyticsEventName: 'In Home FTC Offer',
    enabled: true,
  },
  {
    id: '0eb25000-8ae3-4918-af4e-9069e0c6ec02',
    campaignName: 'digital_landingpage_ftc_1',
    bannerImage: 'promotion-image-1',
    surtitle: 'TIME FOR MORE VACATION VIBES',
    title: 'Get $50 in travel credits',
    subtitle:
      'Ready to spend less on your next stay and get inspiration for your adventures? Fill out the form below to get travel tips in your inbox and <strong>receive $50 in Evolve travel credits</strong> to use toward one of our 30,000+ rentals nationwide.',
    submitButtonText: 'Sign me up to save',
    success,
    userRegisteredError,
    evolveEmployeeError,
    analyticsEventName: 'Online FTC Offer',
    enabled: true,
  },
  {
    id: 'e5d0eb62-8163-4a78-96c4-06c6dcd63471',
    campaignName: 'rebooking_ftc_1',
    bannerImage: 'promotion-image-3',
    surtitle: 'TIME FOR MORE VACATION VIBES',
    title: 'Get $50 off your next stay',
    subtitle:
      "What’s even better than having a wonderful time at an Evolve property? Spending less when you stay with us again! Fill out the form below and <strong>receive $50 off one of our 30,000+ rentals nationwide</strong>. This offer is valid on Evolve.com, where you're guaranteed to find the best rates on all Evolve vacation rentals.",
    submitButtonText: 'Sign me up to save',
    success,
    userRegisteredError,
    evolveEmployeeError,
    analyticsEventName: 'Rebooking FTC Promotion',
    enabled: false,
  },
  {
    id: 'eafca1c7-35bd-44f7-978b-cc3f80b235d2',
    campaignName: 'SMS FTC Promotion',
    bannerImage: 'promotion-image-4',
    surtitle: 'TIME FOR MORE VACATION VIBES',
    title: 'Get $50 in travel credits',
    subtitle:
      'What’s even better than having a wonderful time at an Evolve property? Spending less when you stay with us again! Fill out the form below to <strong>receive $50 in Evolve travel credits</strong> to use toward one of our 30,000+ rentals nationwide.',
    submitButtonText: 'Sign me up to save',
    success,
    userRegisteredError,
    evolveEmployeeError,
    analyticsEventName: 'SMS FTC Promotion',
    sms: true,
    enabled: true,
  },
]

export default promotionData
